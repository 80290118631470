* {
    /* width: 100%;
    height: 100%; */
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    overflow-x: hidden;
    background-color: #fafafa;
}