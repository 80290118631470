@import 'https://fonts.googleapis.com/css?family=Playfair+Display|Montserrat:700';

:root {
    margin: 0;
    padding: 0;
    font-family: "Montserrat";
    font-size: 13px;
}

@media screen and (min-width: 760px) {
    :root {
        font-size: 15px;
    }
}

@media screen and (min-width: 820px) {
    :root {
        font-size: 16px;
    }
}

@media screen and (min-width: 900px) {
    :root {
        font-size: 17px;
    }
}

@media screen and (min-width: 980px) {
    :root {
        font-size: 18px;
    }
}

@media screen and (min-width: 1060px) {
    :root {
        font-size: 19px;
    }
}

@media screen and (min-width: 1220px) {
    :root {
        font-size: 20px;
    }
}

@media screen and (min-width: 1400px) {
    :root {
        font-size: 22px;
    }
}

html,
body,
#app {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

#slideshow {
    position: relative;
    -webkit-animation: fadeIn 500ms ease-in-out forwards;
    animation: fadeIn 500ms ease-in-out forwards;
}

.selectors {
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    background: rgba(0, 0, 0, 0.1);
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.selectors .selector {
    border: 1px solid #fff;
    background: none;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    margin: 5px 0;
    opacity: 0.6;
    cursor: pointer;
    transition: background 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.selectors .selector:hover,
.selectors .selector.active {
    background: white;
    opacity: 0.9;
}

section.wrapper {
    width: 100%;
    height: 100%;
    background-size: cover;
    transition: background-image 500ms ease-in-out;
}

aside.panel {
    width: 40vw;
    height: 100%;
    opacity: 0.9;
    color: #fff;
    box-shadow: 5px 0 25px 0 rgba(0, 0, 0, 0.3);
    transition: background 500ms ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.panel-height {
    height: 20%;
}

aside.panel h2.panel-header,
aside.panel p.panel-info {
    margin: 0;
    padding: 1.3rem 3.5rem;
}

aside.panel h2.panel-header {
    font-weight: normal;
    font-size: 2.5rem;
    text-transform: capitalize;
}

aside.panel p.panel-info {
    font-size: 1.2rem;
    line-height: 1.8em;
}

aside.panel button.panel-button {
    margin: 1.3rem 3.5rem;
    padding: 0.8em 1.3em;
    height: auto;
    width: auto;
    background: none;
    border: 1px solid #fff;
    color: #fff;
    cursor: pointer;
    font-family: "Montserrat";
    font-size: 0.8rem;
    font-weight: 700;
    text-transform: uppercase;
    transition: box-shadow 0.5s ease-in-out, color 0.5s ease-in-out;
}

aside.panel button.panel-button:hover {
    box-shadow: inset 0 0 1.5em 1.5em #fff;
}

aside.panel button.panel-button:focus {
    outline: none;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

section {
    float: left;
    width: 100%;
    background: #fff;
    position: relative;
    box-shadow: 0 0 5px 0px #333;
}


/* Arrow */

.arrow-wrap {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: -11em;
    margin-left: -5em;
    background: #355682;
    width: 10em;
    height: 10em;
    padding: 4em 2em;
    border-radius: 50%;
    font-size: 0.4em;
    display: block;
    box-shadow: 0px 0px 5px 0px #333;
}

.arrow {
    float: left;
    position: relative;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 3em 3em 0 3em;
    border-color: #ffffff transparent transparent transparent;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}

.arrow:after {
    content: '';
    position: absolute;
    top: -3.2em;
    left: -3em;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 3em 3em 0 3em;
    border-color: #355682 transparent transparent transparent;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}


.hint {
    position: absolute;
    top: 0.6em;
    width: 100%;
    left: 0;
    font-size: 2em;
    font-style: italic;
    text-align: center;
    color: #fff;
    opacity: 0;
}


.arrow-wrap:hover .hint {
    opacity: 1;
}


@-webkit-keyframes arrows {
    0% {
        top: 0;
    }

    10% {
        top: 12%;
    }

    20% {
        top: 0;
    }

    30% {
        top: 12%;
    }

    40% {
        top: -12%;
    }

    50% {
        top: 12%;
    }

    60% {
        top: 0;
    }

    70% {
        top: 12%;
    }

    80% {
        top: -12%;
    }

    90% {
        top: 12%;
    }

    100% {
        top: 0;
    }
}

@keyframes arrows {
    0% {
        top: 0;
    }

    10% {
        top: 12%;
    }

    20% {
        top: 0;
    }

    30% {
        top: 12%;
    }

    40% {
        top: -12%;
    }

    50% {
        top: 12%;
    }

    60% {
        top: 0;
    }

    70% {
        top: 12%;
    }

    80% {
        top: -12%;
    }

    90% {
        top: 12%;
    }

    100% {
        top: 0;
    }
}

.arrow-wrap .arrow {
    -webkit-animation: arrows 2.8s 0.4s;
    -webkit-animation-delay: 3s;
    animation: 2.8s 0.4s;
    animation-delay: 3s;
}