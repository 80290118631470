.hero-slider {
    position: relative;
    height: 100vh;
    overflow: hidden;
    padding-top: 100px;
}

.hero-slide {
    height: 100vh;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: white;
}

.gfslide {
    transform: translateY(-10%);
    scale: 102%;
}

.hero-content {
    height: 100%;
    width: auto;
    /* margin-left: 60px; */
    margin-top: 11%;
}

.hero-text-div {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom, rgba(53, 86, 130, 0) 0%, rgba(0, 0, 0, 0.35) 50%, rgba(53, 86, 130, 0) 100%);
    padding: 40px;
}

.gfhtd {
    transform: translateY(20%);
}

.hero-headline {
    font-size: 80px;
    margin: 0;
    text-shadow: 5px 5px 7px rgba(0, 0, 0, 0.5);
    font-family: "Montserrat", sans-serif;
    letter-spacing: 2px;
    /* text-transform: uppercase; */
}

.hero-subheadline {
    font-size: 36px;
    margin-bottom: 40px;
    line-height: 1.5;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    letter-spacing: 2px;
    text-shadow: 5px 5px 7px rgba(0, 0, 0, 0.5);
    /* text-transform: uppercase; */
}

.hero-buttons {
    display: flex;
    gap: 20px;
}

.hero-button {
    font-size: 22px;
    padding: 10px 20px;
    background-color: #355682;
    color: white;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    border: 0 solid transparent;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: background-color 0.3s ease;
    text-transform: uppercase;
    box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.5);
}

.hero-button:hover {
    background-color: #ffffff;
    color: #355682;
}

.slick-dots-custom {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex !important;
    justify-content: center;
    width: auto;
}

.slick-dots-custom li {
    margin: 0 5px;
}

.slick-dot {
    width: 12px;
    height: 12px;
    border: 2px solid white;
    border-radius: 50%;
    cursor: pointer;
}

.slick-dots-custom .slick-active .slick-dot {
    background-color: white;
}

@media (max-width: 1170px) {

    .hero-headline {
        text-align: center;
        font-size: 72px;
    }

    .n1gfa {
        font-size: 60px;
    }

    .hero-subheadline {
        text-align: center;
        font-size: 28px;
        margin-top: 10px;
    }

    .n1gfash {
        font-size: 24px;
    }

    .hero-button {
        font-size: 20px;
        text-align: center;
    }

    .n1gfab {
        font-size: 18px;
    }

}

/* Hero Effects Slide CSS  */

/* .heroEffects {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.heroEffects .bg {
    height: 100%;
    width: 100%;
    background-image: url('../../../public/georgetown-fountain.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    transform: scale(1);
    position: absolute;
    z-index: -1;
}

.heroEffects .bg1 {
    height: 100%;
    width: 100%;
    background-image: url('../../../public/georgetown-sign.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    transform: scale(1);
    position: absolute;
    z-index: -1;
}

.heroEffects .bg2 {
    height: 100%;
    width: 100%;
    background-image: url('../../../public/carriage-house-sign.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    transform: scale(1);
    position: absolute;
    z-index: -1;
}

.heroEffects .bg3 {
    height: 100%;
    width: 100%;
    background-image: url('../../../public/gleason-farms-sign.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    transform: scale(1);
    position: absolute;
    z-index: -1;
}

.heroEffects .shade {
    opacity: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 2;
    height: 100%;
    width: 100%;
    position: absolute;
}

.heroEffects .title {
    width: 100%;
    z-index: 3;
}

.heroEffects .title .text {
    z-index: 4;
    width: 100%;
    text-align: center;
    color: white;
    padding: 4em;
    background: rgba(0, 0, 0, 0.6);
    margin: 0 auto;
}

.centerV {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

h1 {
    font-size: 5em;
    margin-bottom: 0.1em;
    font: 5em/1em;
    color: #fff;
}

p {
    width: 60vw;
    margin: 0 auto;
    line-height: 1.5em;
    padding: 10px 20px 0;
    border-top: solid 1px rgba(255, 255, 255, 0.2);
    letter-spacing: 0.015em;
    display: block;
    color: #eee;
}

.content {
    background: #cdcdcd;
    padding: 150px 50px;
}

.content p {
    color: #111;
    font-size: 2em;
    margin-bottom: 50px;
} */