.navbar {
    background: rgba(255, 255, 255, 0.7);
    transition: background-color .7s ease;
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .9rem;
    position: fixed;
    top: 0;
    z-index: 999;
}

.navbar-container {
    display: flex;
    align-items: center;
    height: 100px;
    width: 100%;
    max-width: 1500px;
    margin: auto;
    padding: 0 50px;
}

.container {
    z-index: 1;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
}

.navbar-logo {
    justify-self: start;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    color: #fff;
    text-transform: uppercase;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.095);
}

.logo-img {
    height: 60px;
    width: auto;
}

.navbar-icon {
    margin-right: 0.7rem;
    height: 50px;
    width: auto;
}

.nav-outer-menu {
    display: flex;
    list-style: none;
    text-align: right;
    justify-content: flex-end;
    /* gap: 50px; */
    /* padding-right: 100px; */
}

.outer-menu-div {
    display: flex;
    align-items: right;
    width: 100%;
    text-align: right;
    justify-content: right;
}

.small-screen {
    display: none;
}

.nav-hamburger-menu {
    display: none;
}

.nav-hamburger-menu.active {
    display: flex;
    flex-direction: column;
    list-style: none;
    position: absolute;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
    width: 40%;
    margin-left: 60%;
    top: 0;
    padding: 5px 30px;
    height: auto;
    background-color: #000;
}

.nav-item {
    text-wrap: nowrap;
    height: 55px;
}

.outer-nav-item {
    height: auto;
    width: auto;
    text-wrap: nowrap;
}

.nav-item-sub {
    text-wrap: nowrap;
    height: 40px;
}

.outer-nav-links {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 18px;
    font-weight: 600;
    gap: 10px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.095);
}

.outer-nav-links:hover {
    color: #729fda;
}

.nav-links {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 22px;
    font-weight: 600;
    width: auto;
    gap: 20px;
    cursor: pointer;
    margin: 0;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.095);
}

.nav-links-properties,
.nav-links-about,
.nav-links-selling,
.nav-links-buying {
    color: #fff;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 16px;
    width: auto;
    gap: 20px;
    padding-left: 60px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.095);
}

.nav-links:hover,
.nav-links-properties:hover,
.nav-links-about:hover,
.nav-links-selling:hover,
.nav-links-buying:hover {
    color: #729fda;
    cursor: pointer;
}

.fa-times {
    height: 30px;
    width: 30px;
    vertical-align: middle;
    cursor: pointer;
}

.menu-icon {
    height: auto;
    width: auto;
    cursor: pointer;
}

.fabars {
    height: 20px;
    width: 20px;
}

.close-menu {
    height: 60px;
    text-wrap: nowrap;
    width: 100%;
    display: flex;
    justify-content: end;
    color: #fff;
    display: inline-flex;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 24px;
    font-weight: 600;
    margin-top: 30px;
    gap: 20px;
    cursor: pointer;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.095);
}

@keyframes rotate180 {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-180deg);
    }
}

@keyframes rotateBack {
    from {
        transform: rotate(-180deg);
    }

    to {
        transform: rotate(0deg);
    }
}

.dropdown-menu {
    display: none;
    position: absolute;
    z-index: 1000;
    width: auto;
    height: auto;
    background-color: #000;
}

.dropdown-menu.active {
    display: block;
}

.dropdown-link {
    color: white;
    padding: 8px 16px;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-size: .9rem;
    display: block;
    text-align: left;
    white-space: normal;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.095);
}

.dropdown-link:hover {
    color: #729fda;
}

.fa-chevron {
    padding-top: 1px;
    height: 20px;
}

.fa-chevron-rotate {
    padding-top: 1px;
    height: 20px;
    animation: rotate180 .3s linear forwards;
}

.fa-chevron-outer {
    padding-top: 1px;
    height: 16px;
    animation: rotateBack .3s linear forwards;
}

.fa-chevron-outer-rotate {
    padding-top: 1px;
    height: 16px;
    animation: rotate180 .3s linear forwards;
}

.dropdown-social-links {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 80%;
    gap: 10px;
    margin: auto;
    margin-top: 15px;
    margin-bottom: 20px;
}

.dropdown-social-icon {
    height: 25px;
}

@media (max-width: 1640px) {

    .nav-outer-menu {
        gap: 30px;
        padding-right: 60px;
    }

    .outer-nav-links {
        gap: 10px;
    }

}

@media (max-width: 1200px) {

    .navbar-logo {
        font-size: 24px;
    }

    .logo-img {
        height: 50px;
        width: auto;
    }

    .outer-nav-item {
        display: none;
    }

    .nav-hamburger-menu.active {
        margin-left: 0;
        width: 100%;
        padding: 5px 25px;
    }

    .close-menu {
        font-size: 18px;
    }

    .fa-times {
        height: 20px;
        width: 20px;
    }

    .nav-links {
        font-size: 18px;
        gap: 15px;
    }

    .nav-item {
        height: 50px;
    }

    .dropdown-social-links {
        gap: 15px;
    }

}

@media (max-width: 768px) {

    .logo-img {
        display: none;
    }

    .navbar-container,
    .container {
        padding: 0;
        height: 100%;
    }

    .menu-icon {
        margin-right: 10%;
        height: auto;
        width: auto;
        margin-top: 0;
    }

    .fabars {
        height: 25px;
        width: 25px;
    }

    .fa-times {
        margin-right: 10%;
    }

}

@media (max-width: 600px) {

    .logo-img {
        display: none;
    }

    .menu-icon {
        margin-right: 10%;
    }

    .fa-times {
        margin-right: 10%;
    }

    .dropdown-social-links {
        display: none;
    }
}